<template>
  <component :is="selected" />
</template>

<script>
import FacebookIcon from '../assets/image/facebook.svg'
import InstagramIcon from '../assets/image/instagram.svg'
import LinkedinIcon from '../assets/image/linkedin.svg'
import TwitterIcon from '../assets/image/x.svg'
import WelcomeToTheJungleIcon from '../assets/image/welcomeToTheJungle.svg'
import YoutubeIcon from '../assets/image/youtube.svg'
import XingIcon from '../assets/image/xing.svg'

export default {
  name: 'SvgIcon',

  components: {
    /* eslint-disable vue/no-unused-components */
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    WelcomeToTheJungleIcon,
    YoutubeIcon,
    XingIcon
  },

  props: {
    icon: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      selected: `${this.icon}Icon`
    }
  }
}
</script>

<style lang="scss">
</style>
