export default {
  state: {
    installed: [],
    list: [
      { name: 'Facebook', link: 'https://www.facebook.com/Lengow/', icon: 'facebook' },
      { name: 'Twitter', link: 'https://x.com/lengow', icon: 'twitter' },
      { name: 'Instagram', link: 'https://www.instagram.com/lengow', icon: 'instagram' },
      { name: 'Linkedin', link: 'https://www.linkedin.com/company/lengow', icon: 'linkedin' },
      { name: 'WelcomeToTheJungle', link: 'https://www.welcometothejungle.com/fr/companies/lengow', icon: 'welcomeToTheJungle' },
      { name: 'YouTube', link: 'https://www.youtube.com/channel/UClCzRG3MEoOW5-60F-1P_7A', icon: 'youtube' },
      { name: 'Xing', link: 'https://www.xing.com/companies/lengow', icon: 'xing' }
    ]
  },
  getters: {},
  mutations: {
    TOGGLE_SOCIAL (state, data) {
      const index = state.installed.findIndex(i => i.name === data.name)
      if (index === -1) {
        state.installed.push(data)
      } else {
        state.installed.splice(index, 1)
      }
    },
    SET_SOCIAL (state, { index, data }) {
      state.installed[index].link = data
    },
    SET_SOCIAL_STATE (state, data) {
      state = Object.assign(state, data)
    },
    SET_INSTALLED_STATE (state, data) {
      state.installed = data
    },
    RESET_SOCIAL (state) {
      state.installed = []
    }
  },
  actions: {
    async toggleSocial ({ state, commit, dispatch, rootState }, data) {
      commit('TOGGLE_SOCIAL', data)
    },
    async updateSocial ({ commit, dispatch, rootState }, { index, data }) {
      commit('SET_SOCIAL', { index, data })
    },
    async updateSort ({ state, commit, dispatch, rootState }, data) {
      commit('SET_INSTALLED_STATE', data)
    }
  }
}
