export default {
  state: {
    fields: [
      { name: 'Full name', type: 'text', value: '' },
      { name: 'Job title', type: 'text', value: '' },
      { name: 'Email', type: 'email', value: '' },
      { name: 'Phone', type: 'text', value: '' }
    ]
  },
  getters: {
    getBasic: state => state
  },
  mutations: {
    SET_BASIC_FIELD (state, { index, data }) {
      state.fields[index] = Object.assign(state.fields[index], data)
    }
  },
  actions: {
    async updateField ({ commit, dispatch, rootState }, { index, data }) {
      commit('SET_BASIC_FIELD', { index, data })
    }
  }
}
