import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import basic from './modules/basic'
import options from './modules/options'
import socials from './modules/socials'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    basic,
    options,
    socials
  }
})
