import { merge } from 'lodash-es'

export default {
  state: {
    ticket: {
      show: false
    },
    calendar: {
      text: 'See my agenda',
      link: '',
      show: false
    },
    banner: {
      default: 'NetRivals',
      show: false,
      options: [
        { label: 'NetRivals', value: 'netrivals' },
        { label: "What does Lengow do (Sophie's video)", value: 'Sophie' },
        { label: 'Recruitment', value: 'Recruitment' },
        { label: "L'Oréal Luxe", value: 'loreal-luxe' }
      ]
    },
    font: {
      size: 12,
      family: 'Arial, Helvetica, sans-serif'
    },
    color: {
      main: '#0056a4',
      secondary: '#0056a4'
    },
    office: {
      selected: 'Nantes',
      options: [
        { label: 'Barcelona', value: 'Barcelona' },
        { label: 'Nantes', value: 'Nantes' },
        { label: 'Paris', value: 'Paris' }
      ]
    },
    company: {
      selected: 'Lengow',
      options: [
        { label: 'Lengow', value: 'Lengow' }
      ]
    },
    baseUrl: 'https://sgn.lengow.net'
  },

  getters: {
    getOptions: state => state
  },
  mutations: {
    SET_OPTIONS (state, data) {
      state = merge(state, data)
    },
    SET_OPTION_STATE (state, data) {
      state = Object.assign(state, data)
    }
  },
  actions: {
    async updateOptions ({ commit, dispatch, rootState }, data) {
      commit('SET_OPTIONS', data)
    }
  }
}
