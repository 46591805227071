<template>
  <el-dialog
    ref="modal"
    class="success-modal"
    :visible="show"
    @update:visible="$emit('update:show', false)"
  >
    <div class="success">
      <h1>🎉 Hooray 🎉</h1>
      <h2>Your signature is successfully created and copied!</h2>
      <br>
      <p>Just Paste it to your email client</p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'Success',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.success {
  text-align: center;
}
.success-modal {
  .el-dialog {
    &__header {
      padding-bottom: 0;
    }
    h1 {
      margin-top: 0;
    }
  }
}
</style>
